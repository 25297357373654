import axios from '@/axios';

class MyCandidateService {
    getCandidates(uriParams) {
        const reducer = (accumulator, currentValue) =>
            accumulator += currentValue[1] !== undefined
                ? (Array.isArray(currentValue[1]) && currentValue[1].length === 0 ? '' : currentValue.join('=') + '&')
                : '';

        let url = '/my/candidates' + Object.entries(uriParams).reduce(reducer, '?').slice(0, -1)

        return axios.get(url)
    }
    getCandidate(candidateId) {
        return axios.get(`/my/candidates/${candidateId}`)
    }
    addCandidate(data) {
        return axios.post('/my/candidates', data)
    }
    updateProfileImage(candidateId, formData) {
        return axios.post(`/my/candidates/${candidateId}/image`, formData)
    }
    updateCandidate(candidateId, data) {
        return axios.put(`/my/candidates/${candidateId}`, data)
    }
    uploadCVFile(candidateId, formData) {
        return axios.post(`/my/candidates/${candidateId}/CV`, formData)
    }
}

export const myCandidateService = new MyCandidateService();