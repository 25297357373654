<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Create Candidate') }}</h3>
                    <p>{{ groupName }}</p>
                </div>

                <FormErrors v-if="errors[`group_name`]" :errors="errors[`group_name`]" />

                <form @submit.prevent="addCandidate" class="invite-form">
                    <div class="form-group">
                        <input v-model="candidate.profile.first_name" :placeholder="$t('First name')" type="text" name="first_name" :class="['form-control', {'has-error': errors['profile.first_name']}]">
                        <FormErrors v-if="errors[`profile.first_name`]" :errors="errors[`profile.first_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="candidate.profile.last_name" :placeholder="$t('Last name')" type="text" name="last_name" :class="['form-control', {'has-error': errors['profile.last_name']}]">
                        <FormErrors v-if="errors[`profile.last_name`]" :errors="errors[`profile.last_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="candidate.email" type="email" :placeholder="$t('E-Mail')" name="email" :class="['form-control', {'has-error': errors['email']}]">
                        <FormErrors v-if="errors[`email`]" :errors="errors[`email`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="candidate.profile.phone_number" :placeholder="$t('Phone number')" name="phone_number" :class="['form-control', {'has-error': errors['profile.phone_number']}]">
                        <FormErrors v-if="errors[`profile.phone_number`]" :errors="errors[`profile.phone_number`]" />
                    </div>

<!--                    <div class="form-group">-->
<!--                        <input v-model="candidate.password" type="password" :placeholder="$t('Password')" :class="['form-control', {'has-error': errors['password']}]">-->
<!--                        <FormErrors v-if="errors[`password`]" :errors="errors[`password`]" />-->
<!--                    </div>-->

<!--                    <div class="form-group">-->
<!--                        <input v-model="candidate.password_confirmation" :placeholder="$t('Password confirmation')" type="password" :class="['form-control', {'has-error': errors['password_confirmation']}]">-->
<!--                        <FormErrors v-if="errors[`password_confirmation`]" :errors="errors[`password_confirmation`]" />-->
<!--                    </div>-->

                    <div class="form-group">
                        <Multiselect
                            v-model="candidate.profile.language_id"
                            :options="languages"
                            :placeholder="$t('Select language')"
                            :class="['form-control', {'has-error': errors['profile.language_id']}]"
                            :searchable="true"
                        />
                        <FormErrors v-if="errors[`profile.language_id`]" :errors="errors[`profile.language_id`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="candidate.profile.country_id"
                            :options="countries"
                            :placeholder="$t('Select country')"
                            :class="['form-control', {'has-error': errors['profile.country_id']}]"
                            :searchable="true"
                        />
                        <FormErrors v-if="errors[`profile.country_id`]" :errors="errors[`profile.country_id`]" />
                    </div>
                    
                    <button class="btn btn-primary">{{ $t('Create') }}</button>
                </form>

            </div>
        </template>
    </Modal>
</template>

<script>
import axios from "@/axios";
import FormErrors from "@/components/widgets/FormErrors";
import Modal from "@/components/widgets/Modal";
import Multiselect from '@vueform/multiselect'
import {myCandidateService} from "@/services/myCandidateService";

export default {
    name: "AddCandidateComponent",
    components: {
        FormErrors,
        Modal,
        Multiselect
    },
    data() {
        return {
            candidate: {
                profile: {},
            },


        }
    },
    emits: ['hideComponent'],
    computed: {
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    methods: {
        addCandidate() {
            myCandidateService.addCandidate(this.candidate)
                .then(response => {
                    this.$store.commit('addCandidate', response.data.data)
                    this.$emit('closeModal')
                })
                .catch(error => this.errors = error.response.data.errors || [])
        }
    }
}
</script>

<style scoped>

</style>