<template>
    <div class="container-fluid view candidates">
        
        <div class="sidebar-view">
            <left-sidebar-candidates-component @search="getCandidates" :search="search" />
        </div>

        <div class="content-view">
            <div class="d-flex justify-content-between">
                <div class="title">
                    <h1>{{ $t("Candidates") }}</h1>
                </div>
                <div class="buttons">
                    <a @click="showAddComponent=true" href="#" class="btn btn-primary">{{ $t('ADD') }}</a>
                </div>
            </div>

            <div class="top-search">
                <div class="form-group icon-input wide-input">
                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20.155" height="20.176" viewBox="0 0 20.155 20.176">
                        <path d="M30.576 29.224l-3.73-3.73a8.9 8.9 0 1 0-1.427 1.452l3.73 3.73a.988.988 0 0 0 .7.3.909.909 0 0 0 .7-.3 1.052 1.052 0 0 0 .027-1.452zm-3.755-9.387a7.008 7.008 0 1 1-2.053-4.982 7.005 7.005 0 0 1 2.053 4.982z" transform="translate(-10.7 -10.8)"/>
                    </svg>
                    <input type="text" class="form-control" v-model.trim="search.q" :placeholder="$t('Search')">
                </div>
                <div class="form-group">
                    <input v-model.trim="search.where" @input="debounceGetCandidates" class="form-control-rounded" type="text" :placeholder="$t('Search by location')">
                </div>
                <div class="form-group icon-input multiselect-input align-right">
                    <Multiselect
                        v-model="sortBy"
                        :options="sortList"
                        @select="getCandidates"
                        :placeholder="$t('Sort by')"
                        class="form-control"
                    />
                </div>
            </div>
            
            <candidate-list-component v-for="candidate in candidates" :key="candidate.id" :candidate="candidate" />

            <button class="btn btn-secondary btn-secondary-smaller load-more">{{ $t('Load More') }}</button>

        </div>
    </div>
    <transition name="fade">
        <add-candidate-component v-if="showAddComponent" @closeModal="showAddComponent=false" />
    </transition>

</template>

<script>
import _ from "lodash";
import AddCandidateComponent from "@/components/candidates/AddCandidateComponent";
import {candidateService} from "@/services/candidateService";
import {myCandidateService} from "@/services/myCandidateService";
import LeftSidebarCandidatesComponent from "../../components/candidates/LeftSidebarComponent";
import CandidateListComponent from "../../components/candidates/CandidateListComponent";
import Multiselect from '@vueform/multiselect'

export default {
    name: "Candidates",
    data() {
        return {

            search: {
                q: this.$route.query.q,
                category: this.$route.query.category,
                industry: this.$route.query.industry ? this.$route.query.industry.split(',') : [],
                employment: this.$route.query.employment ? this.$route.query.employment.split(',') : [],
                where: this.$route.query.where,
                salary_min: this.$route.query.salary_min,
                salary_max: this.$route.query.salary_max,
            },
            page: this.$route.query.page,
            showAddComponent: false,
            sortBy: '',
            sortDir: '',
        }
    },
    computed: {
        candidates() {
            return this.$store.state.candidates.candidates
        },
        loggedIn() {
            return this.$store.getters.loggedIn
        },
        sortList() {
            return [
                {
                    label: this.$t('Create Date'),
                    value: 'create_date'
                },
                {
                    label: this.$t('Update Date'),
                    value: 'update_date'
                },
                {
                    label: this.$t('Salary'),
                    value: 'salary'
                }
            ];
        }
    },
    created() {
        this.getCandidates()
    },
    methods: {
        getCandidates() {
            if (this.loggedIn) {
                myCandidateService.getCandidates(this.search)
                    .then(response => {
                        this.$store.commit('setCandidates', response.data.data)
                    })
                    .catch(error => {
                        this.errors = error.response.data
                    })
            } else {
                candidateService.getCandidates(this.search)
                    .then(response => {
                        this.$store.commit('setCandidates', response.data.data)
                    })
                    .catch(error => {
                        this.errors = error.response.data
                    })
            }
        },
        debounceGetCandidates: _.debounce(function () {
            this.getCandidates()
        }, 1000)
    },
    watch: {
        'search': {
            handler: function (after, before) {
                this.getCandidates()
            },
            deep: true
        },
    },
    components: {
        CandidateListComponent,
        LeftSidebarCandidatesComponent,
        AddCandidateComponent,
        Multiselect
    }
}
</script>