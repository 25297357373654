<template>
    <div class="sidebar-filters">
        <h4>{{ $t("Salary") }}</h4>

        <section class="range-slider">
            <span class="range-value">{{ search.salary_min || 0 }} - {{ search.salary_max || 100 }} {{ $t('Eur per hour') }}</span>
            <div class="min-max-ranges">
                <input @input="search.salary_min=$event.target.value" :value="[search.salary_min || 0]" min="0" max="100" step="1" type="range">
                <input @input="search.salary_max=$event.target.value" :value="[search.salary_max || 100]" min="0" max="100" step="1" type="range">
            </div>
        </section>

        <hr/>

        <h4>{{ $t("Job type") }}</h4>
        <ul>
            <li v-for="industry in $store.getters.industries" :key="industry.value">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" :id="'industry-'+industry.value" :value="industry.value" v-model="search.industry">
                    <label class="form-check-label" :for="'industry-'+industry.value">
                        {{ industry.label }}
                    </label>
                </div>
            </li>
        </ul>

        <hr/>

        <h4>{{ $t("Employment type") }}</h4>
        <ul>
            <li v-for="employmentType in employmentTypes" :key="employmentType.id">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"  :id="'employment-'+employmentType.id" :value="employmentType.id" v-model="search.employment">
                    <label class="form-check-label" :for="'employment-'+employmentType.id">
                        {{ employmentType.name }}
                    </label>
                </div>
            </li>
        </ul>

        <button @click.prevent="$emit('search')" id="trigger-search" class="btn btn-primary"><i
            class="fa fa-search"></i> {{ $t('Search') }}
        </button>
    </div>

</template>

<script>


export default {
    name: "LeftSidebarCandidatesComponent",
    emits: ['search'],
    props: ['search'],
    computed: {
        employmentTypes() {
            return this.$store.getters.employmentTypes
        }
    }
}
</script>

<style scoped>

</style>