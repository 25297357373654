<template>
    <div class="item viewable" @click="openPreview">
        <div class="item-wrapper">
            <div class="image">
                <img :src="candidate.image" :alt="`${candidate.first_name} ${candidate.last_name}`" />
            </div>
            <div class="content">
                <p class="industry" v-if="candidate.industry_name">{{ candidate.industry_name }}</p>
                <h2>{{ candidate.first_name }} {{ candidate.last_name }}</h2>

                <ul class="item-list">
                    <li v-if="candidate.country_name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                            <g>
                                <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                            </g>
                        </svg>
                        <span>{{ candidate.country_name }}</span>
                    </li>
                    <li v-if="candidate.hourly_rate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                            <g>
                                <g>
                                    <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                </g>
                            </g>
                        </svg>
                        <span>{{ candidate.hourly_rate }}</span>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                            <g>
                                <g>
                                    <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                </g>
                            </g>
                        </svg>
                        <span>views</span>
                    </li>
                </ul>

                <hr/>

                <p class="card-text" v-if="candidate.description">{{ candidate.description }}</p>

                <router-link :to="{ name: 'ViewCandidate', params: { id: candidate.id } }">
                    <button class="btn btn-secondary icon-btn" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                            <g>
                                <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                            </g>
                        </svg>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
    <transition name="slide">
        <div class="item-preview" v-if="showPreview">
            <div class="item-container">
                <button class="btn icon-btn close-btn" @click="showPreview = false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                        <g>
                            <g>
                                <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <CandidateViewItem :_id="candidate.id" />
            </div>
        </div>
    </transition>
</template>

<script>
import slugify from "@/js/slugify";
import CandidateViewItem from '@/views/candidates/ViewCandidate';

export default {
    name: "CandidateListComponent",
    components: {
        CandidateViewItem
    },
    data() {
        return {
            slugify: slugify,
            showPreview: false
        }
    },
    methods: {
        openPreview() {
            this.showPreview = true;
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        }
    },
    props: ['candidate'],
}
</script>